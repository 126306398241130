<template>
  <template v-if="isLoggedIn">
    <NavbarItem>
      <a href="/shortener/urls">My Links</a>
    </NavbarItem>
    <NavbarItem>
      <a href="/shortener/groups">My Collections</a>
    </NavbarItem>
    <NavbarItem v-if="isReviewer">
      <a href="/shortener/admin/urls">All Links</a>
    </NavbarItem>
    <NavbarDropdown v-if="isAdmin" label="Admin">
      <NavbarItem>
        <a href="/shortener/admin/urls">All Urls</a>
      </NavbarItem>
      <NavbarItem>
        <a href="/shortener/admin/groups"> All Collections </a>
      </NavbarItem>
      <NavbarItem>
        <a href="/shortener/admin/members"> Manage Admins </a>
      </NavbarItem>
      <NavbarItem>
      <a href="/shortener/admin/reviewers"> Manage Reviewers </a>
      </NavbarItem>
      <NavbarItem>
      <a href="/shortener/admin/bulk_import">Bulk Import</a>
      </NavbarItem>
      <NavbarItem>
      <a href="/shortener/admin/stale_urls">Stale Urls</a>
      </NavbarItem>
      <NavbarItem>
        <a href="/shortener/admin/audits"> Audit Logs </a>
      </NavbarItem>
      <NavbarItem>
        <a href="/shortener/admin/announcements"> Announcements </a>
      </NavbarItem>
    </NavbarDropdown>
  </template>
  <NavbarDropdown label="Help">
    <NavbarItem>
      <a
        href="https://itsupport.umd.edu/itsupport/enhttps://itsupport.umd.edu/itsupport?id=kb_article_view&sysparm_article=KB0018383"
        >How To Guide</a
      >
    </NavbarItem>
    <NavbarItem>
      <a href="https://itsupport.umd.edu/itsupport?id=kb_article_view&sysparm_article=KB0018382">FAQ</a>
    </NavbarItem>
    <NavbarItem>
      <a href="/shortener/api_keys">API</a>
    </NavbarItem>
    <NavbarItem>
      <a href="https://itsupport.umd.edu/itsupport/?id=getsupport">Contact Us</a>
    </NavbarItem>
  </NavbarDropdown>
  <NavbarItem v-if="!isLoggedIn">
    <a href="/shortener/signin">Sign In</a>
  </NavbarItem>
  <NavbarItem v-if="isLoggedIn">
    <a href="/shortener/signout">Sign Out</a>
  </NavbarItem>
</template>
<script setup lang="ts">
import NavbarItem from "@/components/templates/NavbarItem.vue";
import NavbarDropdown from "@/components/templates/NavbarDropdown.vue";
import type { User } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  currentUser: User | null;
}>();

const isLoggedIn = computed(() => props.currentUser !== null);
const isAdmin = computed(() => props.currentUser?.admin ?? false);
const isReviewer = computed(() => props.currentUser?.reviewer ?? false);
</script>
<style scoped></style>
